.page-login
{
	width: 100%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.page-login .col-left
{
	background: #f5f5f5;
	width: 50%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 36px;
	box-sizing: border-box;
}
.page-login .col-left img
{
	width: 100%;
	max-width: 610px;
}

.page-login .section-title
{
	margin-bottom: 32px;
}

.page-login .col-right
{
	width: 50%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 100px 108px 50px 108px;
	box-sizing: border-box;
}

.page-login .col-right .section-top
{
	width: 100%;
	/*display: inline-flex;*/
	/*align-items: center;*/
	/*justify-content: start;*/
	/*flex-direction: column;*/
}

/*.page-login .form-login*/
/*{*/
/*	width: 100%;*/
/*}*/

.page-login .form-forget-remember
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
}

.page-login .col-right .btn-box
{
	width: 100%;
}
.page-login .col-right .btn-custom
{
	margin-top: 24px;
}
