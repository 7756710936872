@font-face
{
	font-family: 'MetronicPro';
	font-weight: 200;
	font-style: normal;
	src: url("./fonts/MetronicPro-Thin.eot");
	src: url("./fonts/MetronicPro-Thin.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-Thin.woff2") format("woff2"),
	url("./fonts/MetronicPro-Thin.woff") format("woff"),
	url("./fonts/MetronicPro-Thin.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 300;
	font-style: normal;
	src: url("./fonts/MetronicPro-Light.eot");
	src: url("./fonts/MetronicPro-Light.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-Light.woff2") format("woff2"),
	url("./fonts/MetronicPro-Light.woff") format("woff"),
	url("./fonts/MetronicPro-Light.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 400;
	font-style: normal;
	src: url("./fonts/MetronicPro.eot");
	src: url("./fonts/MetronicPro.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro.woff2") format("woff2"),
	url("./fonts/MetronicPro.woff") format("woff"),
	url("./fonts/MetronicPro.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 600;
	font-style: normal;
	src: url("./fonts/MetronicPro-SemiBold.eot");
	src: url("./fonts/MetronicPro-SemiBold.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-SemiBold.woff2") format("woff2"),
	url("./fonts/MetronicPro-SemiBold.woff") format("woff"),
	url("./fonts/MetronicPro-SemiBold.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 700;
	font-style: normal;
	src: url("./fonts/MetronicPro-Bold.eot");
	src: url("./fonts/MetronicPro-Bold.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-Bold.woff2") format("woff2"),
	url("./fonts/MetronicPro-Bold.woff") format("woff"),
	url("./fonts/MetronicPro-Bold.ttf") format("truetype");
}

/**********************************************************************************************************************/

body
{
	background-color: #ffffff;
	min-width: 320px;
	color: #01021D;
	font-family: "MetronicPro", sans-serif !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5em;
	padding: 0;
	margin: 0;
	cursor: default;
	direction: ltr;
	overflow: hidden;
}
body, html, #root, .main-body
{
	background-color: #ffffff;
	width: 100%;
	min-width: 320px;
	height: 100%;
	position: relative;
}
/*
.page-login, .page-forgot-password, .page-register
{
	background-color: #F5F6F7;
}
*/
.scrollbar
{
	direction: ltr;
}

*:focus
{
	box-shadow: none !important;
}

a, a:visited
{
	text-decoration: none !important;
}

.display-none
{
	display: none !important;
}

.clear-both
{
	clear: both;
}

.m-0
{
	margin: 0 !important;
}
.m-l-16
{
	margin-left: 16px !important;
}
.m-l-32
{
	margin-left: 32px !important;
}
.m-t-0
{
	margin-top: 0 !important;
}
.m-t--16
{
	margin-top: -16px !important;
}
.m-t-8
{
	margin-top: 8px !important;
}
.m-t-16
{
	margin-top: 16px !important;
}
.m-t-32
{
	margin-top: 32px !important;
}
.m-b-16
{
	margin-bottom: 16px !important;
}
.m-b-32
{
	margin-bottom: 32px !important;
}
.m-r-l-4
{
	margin: 0 4px !important;
}
.m-r-8
{
	margin-right: 8px !important;
}

.p-b-0
{
	padding-bottom: 0 !important;
}

.h-100
{
	height: 100px !important;
}
.h-300
{
	height: 300px !important;
}

button, .text-transform-unset
{
	text-transform: unset !important;
}

.text-center
{
	text-align: center !important;
}

.container
{
	margin-right: auto;
	margin-left: auto;
	box-sizing: border-box;
	overflow: hidden;
}
.row
{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -14px;
	box-sizing: border-box;
}
.row [class*='col-']
{
	padding: 0 14px;
	box-sizing: border-box;
}
.row .col-12
{
	width: 100%;
}
.row .col-11
{
	width: 91.66666667%;
}
.row .col-10
{
	width: 83.33333333%;
}
.row .col-9
{
	width: 75%;
}
.row .col-8
{
	width: 66.66666667%;
}
.row .col-7
{
	width: 58.33333333%;
}
.row .col-6
{
	width: 50%;
}
.row .col-5
{
	width: 41.66666667%;
}
.row .col-4
{
	width: 33.33333333%;
}
.row .col-3
{
	width: 25%;
}
.row .col-2
{
	width: 16.66666667%;
}
.row .col-1
{
	width: 8.33333333%;
}

/**********************************************************************************************************************/

/* col content */

.col-content
{
	background-color: #f5f5f5;
	width: calc(100% - 258px);
	height: 100%;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: 0;
}

@media(max-width: 900px)
{
	.col-content
	{
		width: 100%;
	}
}

/* *** */

.col-content .top-bar
{
	background-color: #ffffff;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 30px;
	box-sizing: border-box;
}


.col-content .top-bar .breadcrumb
{
	display: inline-flex;
	align-items: center;
	padding: 0;
	margin: 0;
}
.col-content .top-bar .breadcrumb li
{
	color: #01021D;
	font-size: 14px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	margin-right: 18px;
	list-style: none;
	position: relative;
}
.col-content .top-bar .breadcrumb li:last-child
{
	margin-right: 0;
}
.col-content .top-bar .breadcrumb li:before
{
	background-color: #e5e5e5;
	width: 6px;
	height: 6px;
	border-radius: 100%;
	content: '';
	position: absolute;
	top: 50%;
	right: -12px;
	transform: translateY(-50%);
}
.col-content .top-bar .breadcrumb li:last-child:before
{
	display: none;
}
.col-content .top-bar .breadcrumb li a
{
	color: rgba(1, 2, 29, 0.5);
	font-size: 14px;
	font-weight: 300;
	display: inline-flex;
	align-items: center;
	transition: color 500ms ease-in-out;
}
.col-content .top-bar .breadcrumb li a:hover
{
	color: #CF2222;
}
.col-content .top-bar .breadcrumb .icon
{
	color: #01021D;
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.col-content .top-bar .btn-menu-drawer
{
	display: none !important;
	margin: 0 24px 0 0 !important;
}

.col-content .top-bar .col-icon
{
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
}
.col-content .top-bar .icon-box
{
	background-color: #ffffff;
	width: 48px;
	height: 48px;
	color: #01021D;
	font-size: 16px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 14px;
	border: 1px solid #EEEEEE;
	border-radius: 100%;
	cursor: pointer;
	transition: all 500ms ease-in-out;
}
.col-content .top-bar .icon-box .char-color2
{
	opacity: 0.4;
}
.col-content .top-bar .icon-box .icon
{
	width: 22px;
	height: 22px;
}
.col-content .top-bar .icon-box:hover
{
	border-color: #CF2222;
}

@media(max-width: 900px)
{
	.col-content .top-bar .btn-menu-drawer
	{
		display: inline-flex !important;
	}
}

/* *** */

.col-content .section-footer
{
	margin-top: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
}
.col-content .section-footer.flex-end
{
	justify-content: flex-end;
}

/* *** */

.col-content .page-content
{
	background-color: #ffffff;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 30px;
	margin: 30px;
	border: 1px solid #F3F3F3;
	border-radius: 4px;
	position: relative;
}

.col-content .page-content .page-description
{
	line-height: 30px;
	color: #01021D;
	font-weight: 200;
	font-size: 16px;
	text-align: justify;
}
.col-content .page-content .page-description h1,
.col-content .page-content .page-description h2,
.col-content .page-content .page-description h3,
.col-content .page-content .page-description h4,
.col-content .page-content .page-description h5
{
	line-height: 30px;
	color: #01021D;
	font-weight: 700;
	font-size: 16px;
	text-align: justify;
	padding: 0;
	margin: 0;
}
.col-content .page-content p
{
	margin: 0;
}
.col-content .page-content .image-box
{
	width: 100%;
	text-align: center;
	margin: 42px 0;
}
.col-content .page-content .image-box img
{
	width: 100%;
	max-width: 700px;
}
.col-content .page-content .video-box
{
	width: 100%;
	max-width: 700px;
	text-align: center;
	margin: 42px auto;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}
.col-content .page-content .video-box.no-play:before
{
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
}
.col-content .page-content .video-box .btn-play
{
	background-image: url("../images/icon-play.svg");
	width: 52px;
	height: 52px;
	content: '';
	position: absolute;
	bottom: 24px;
	right: 24px;
	cursor: pointer;
}
.col-content .page-content .video-box video
{
	width: 100%;
	max-width: 700px;
}
.col-content .page-content .video-box .caption
{
	width: 100%;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 400;
	text-align: left;
	padding: 24px;
	position: absolute;
	left: 0;
	bottom: 0;
}

.page-content-404
{
	/*width: 100%;*/
	height: 100%;
	position: relative;
}

/* *** */
/*
.col-content .page-content .title-bar-breadcrumbs-search
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}

.col-content .page-content .title-bar
{
	width: 100%;
	margin-bottom: 24px;
	display: inline-flex;
	align-items: center;
	box-sizing: border-box;
}
.col-content .page-content .title-bar .title
{
	color: #01021D;
	font-size: 18px;
	font-weight: bold;
	float: left;
}

.col-content .page-content .breadcrumbs
{
	width: 100%;
	color: #01021D;
	margin: -16px 0 0 0;
}
.col-content .page-content .breadcrumbs .link, .col-content .page-content .breadcrumbs .link:visited
{
	color: #01021D;
	font-size: 14px;
}
.col-content .page-content .breadcrumbs .link:hover
{
	color: #8ba0b7;
}
.col-content .page-content .breadcrumbs .current-page
{
	color: #01021D;
	font-size: 14px;
}

.col-content .page-content .breadcrumbs .MuiBreadcrumbs-separator
{
	color: #9e9e9e;
	margin: 0;
}
*/
/* *** */
/*
.col-content .page-content .filter-bar
{
	margin-bottom: 24px;
}

.col-content .page-content .filter-bar .filter-select-box
{

}
.col-content .page-content .filter-bar .filter-select-box .MuiSelect-select
{
	background-color: #ffffff;
	width: 100px;
}
.col-content .page-content .filter-bar .filter-select-box fieldset
{
	border: 0;
}

.col-content .page-content .search-box
{
	background-color: #ffffff;
	width: 100%;
	max-width: 240px;
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
}
.col-content .page-content .search-box .icon
{
	flex-basis: 40px;
	height: 40px;
	color: #01021D;
	padding: 6px;
	cursor: pointer;
	box-sizing: border-box;
}
.col-content .page-content .search-box .form-input
{
	margin: 0;
}
.col-content .page-content .search-box .form-input input
{
	height: 40px;
	color: #01021D;
	padding-right: 0;
	border: 0;
	outline: none;
}
.col-content .page-content .search-box .form-input input:focus
{
	border: 0 !important;
}
*/
/**********************************************************************************************************************/

.page-404
{
	width: 100%;
	height: 100%;
	position: relative;
}
.page-404 img
{
	width: 100%;
	max-width: 600px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/**********************************************************************************************************************/

::-webkit-input-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
:-moz-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
::-moz-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
:-ms-input-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}

.form-input-box
{
	width: 100%;
	margin-bottom: 24px;
	position: relative;
}
.form-input-box.display-flex
{
	display: inline-flex;
	align-items: center;
}
/*.form-input-box */.form-label
{
	color: #01021D;
	font-size: 14px;
	font-weight: 700;
	display: block;
	margin-bottom: 5px;
}
.form-input-box .form-hint
{
	color: #a3a3a3;
	font-size: 12px;
	font-weight: 400;
	margin-top: 10px;
}
.form-price-separate
{
	color: #a3a3a3;
	font-size: 14px;
	font-weight: 400;
	margin-left: 10px;
	letter-spacing: 2px;
}
.form-input-box .form-input, .form-input-box .form-select, .form-input-box .form-textarea,
.form-input, .form-select, .form-textarea
{
	background-color: #F5F5F5;
	width: 100%;
	height: 52px;
	color: #01021D;
	font-size: 14px;
	font-weight: 400;
	font-family: "MetronicPro", sans-serif !important;
	display: block;
	padding: 14px 38px 14px 14px;
	/*margin-bottom: 24px;*/
	border: 1px solid #EEEEEE;
	border-radius: 8px;
	box-shadow: unset;
	outline: unset;
	box-sizing: border-box;
	resize: none;
}
.form-input-box .form-input.no-icon, .form-input-box .form-textarea.no-icon
{
	padding: 14px;
}
.form-input-box .form-select
{
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}
.form-input-box .form-input:focus, .form-input-box .form-select:focus, .form-input-box .form-textarea:focus
{
	border-color: #bababa;
}
.form-input-box .icon
{
	color: #ADADAD;
	font-size: 20px;
	position: absolute;
	top: 16px;
	right: 14px;
}

.form-input-box .input-radio-box
{
	display: inline-block;
	cursor: pointer;
}

.form-input-box .btn-file
{
	background-color: #01021d;
	height: 52px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 16px;
	border-radius: 8px;
	box-sizing: border-box;
	cursor: pointer;
}
.form-input-box .btn-file:hover
{
	background-color: #CF2222;
}
.form-input-box .btn-file input
{
	display: none;
}

.form-checkbox
{
	font-weight: 300;
	font-size: 16px;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
}
.form-checkbox .icon
{
	background-color: #ffffff;
	width: 24px;
	height: 24px;
	color: #CF2222;
	margin-right: 10px;
	border: 1px solid #CF2222;
	border-radius: 6px;
}

.tab-language
{
	margin-bottom: 24px;
}

/*
.form-box .form-title
{
	font-size: 24px;
	font-weight: bold;
	margin: 0;
}
.form-box .form-container
{
	max-width: unset;
}
.form-box .form-container .MuiGrid-container
{
	margin-top: 0;
}
.form-box .form-container .MuiGrid-item
{
}

.form-box .form-divider
{
	width: 100%;
	height: 1px;
}

.form-box .form-label
{
	line-height: unset;
	color: #01021D;
	font-family: inherit;
	font-size: 14px;
	text-align: left;
	float: left;
	margin-bottom: 8px;
	transform: none;
	letter-spacing: unset;
}
.form-box .form-label.form-label-m0
{
	margin-bottom: 0;
}
.form-box .form-label .optional
{
	color: #6c757d;
	font-size: 12px;
	font-style: italic;
}
.form-box .form-input, .form-box .form-select, .form-box .form-input-number
{
	width: 100%;
	margin-bottom: 16px;
}
.form-box.form-box-t2 .form-input, .form-box.form-box-t2 .form-select, .form-box.form-box-t2 .form-switch
{
	margin-bottom: 24px;
}
.form-box .form-input input, .form-box .form-select .MuiSelect-select, .form-box .form-input textarea, .form-box .form-input-number input
{
	background-color: #fdfdfd;
	width: 100%;
	height: 40px;
	font-size: 14px;
	padding: 0 16px 0 16px;
	border: 1px solid #e5e7e8;
	border-radius: 4px;
	box-sizing: border-box;
}
.form-box .form-input textarea
{
	height: 120px !important;
	padding: 16px;
	overflow: auto !important;
}
.form-box .form-input .MuiInputBase-multiline
{
	padding: 0;
}
.form-box .MuiCheckbox-root
{
	padding-top: 0;
}
.form-box .form-select .MuiSelect-select
{
	padding-top: 7px;
}
.form-box .form-input input:focus, .form-box .form-select .MuiSelect-select:focus, .form-box .form-input textarea:focus, .form-box .form-input-number input:focus
{
	border: 1px solid #005ce8 !important;
}
.form-box .form-input fieldset, .form-box .form-select fieldset, .form-box .form-input-number fieldset
{
	border: 0;
}
.form-box .form-input.form-input-half
{
	width: 50%;
}
.form-box .form-input.error input
{
	border: 1px solid #ffa4a5 !important;
}

.form-box .form-input-search .MuiInputBase-formControl
{
	padding: 0;
	border-radius: 0;
}
.form-box .form-input-search input
{
	padding: 0 16px 0 16px !important;
}

.form-box.form-box-t2 .form-button-group
{
	margin-bottom: 24px;
	border-radius: 0;
	box-shadow: unset;
}
.form-box.form-box-t2 .form-button-group button
{
	background-color: #f5f6f7;
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	border: 0;
	border-bottom: 2px solid transparent;
	text-transform: unset;
}
.form-box.form-box-t2 .form-button-group button.Mui-selected
{
	background-color: #ffffff;
	color: #01021D;
	border-bottom: 2px solid #005ce8;
}

.form-box.form-box-t2 .form-control-label-check-box
{
	margin-bottom: 24px;
}
.form-box.form-box-t2 .form-control-label-check-box .MuiFormControlLabel-label
{
	color: #01021D;
	font-size: 14px;
	text-align: left;
	transform: none;
}

.form-box .MuiSwitch-root
{
	background-color: #005ce8;
	width: 32px;
	height: 20px;
	padding: 0;
	border-radius: 33px;
}
.form-box .MuiSwitch-root .MuiSwitch-track
{
	opacity: 0 !important;
}
.form-box .MuiSwitch-root .MuiSwitch-switchBase
{
	width: 12px;
	height: 12px;
	padding: 6px 0 0 0;
	left: 4px;
}
.form-box .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked
{
	color: #ffffff;
	-webkit-transform: translateX(12px);
	-moz-transform: translateX(12px);
	-ms-transform: translateX(12px);
	transform: translateX(12px);
}
.form-box .MuiSwitch-root .MuiSwitch-thumb
{
	width: 12px;
	height: 12px;
}

.form-box .react-datepicker-ignore-onclickoutside
{
	outline: unset !important;
}

.form-box .form-content .MuiGrid-item
{
	padding-top: 0 !important;
	position: relative;
}

.form-box .form-btn-password-show-hide
{
	color: #01021D;
	cursor: pointer;
	position: absolute;
	top: 36px;
	right: 10px;
}
.form-box .form-btn-password-show-hide:hover
{
	color: #959fa3;
}
*/
/**********************************************************************************************************************/

.table-data
{
	background-color: #ffffff;
	width: 100%;
	border-spacing: 0;
	position: relative;
	z-index: 0;
}

.table-data thead
{
	background-color: #f8f9f9;
}
.table-data thead.sticky
{
	position: sticky;
	top: 0;
	z-index: 1;
}
.table-data thead th
{
	color: #01021D;
	font-size: 12px;
	font-weight: 700;
	text-align: left;
	padding: 16px;
	box-sizing: border-box;
	white-space: nowrap;
}
/*.table-data thead th.center*/
/*{*/
/*	text-align: center;*/
/*}*/
/*.table-data thead th.center .col-value*/
/*{*/
/*	justify-content: center;*/
/*}*/
/*.table-data thead .col-value*/
/*{*/
/*	width: 100%;*/
/*	display: flex;*/
/*	align-items: center;*/
/*}*/
/*.table-data thead .col-sort*/
/*{*/
/*	color: #9e9e9e;*/
/*	font-size: 20px;*/
/*	transform: rotate(90deg);*/
/*}*/
/*.table-data thead .col-sort.asc*/
/*{*/
/*	color: #005ce8;*/
/*	transform: rotate(-90deg);*/
/*}*/
/*.table-data thead .col-sort.desc*/
/*{*/
/*	color: #005ce8;*/
/*	transform: rotate(90deg);*/
/*}*/

/*.table-data tbody*/
/*{*/

/*}*/
.table-data tbody td
{
	color: #01021D;
	font-size: 14px;
	font-weight: 400;
	text-align: left;
	padding: 16px;
	border-top: 1px solid #eeeeee;
	white-space: nowrap;
}
/*.table-data tbody td.center*/
/*{*/
/*	text-align: center;*/
/*}*/
.table-data tbody tr.new td
{
	font-weight: 700;
}
.table-data tbody tr:hover td
{
	background-color: rgba(207, 34, 34, 0.03);
	/*color: #01021D;*/
}

.table-data tbody a, .table-data tbody a:visited
{
	color: #005ce8;
	font-size: 14px;
}
.table-data tbody a:hover
{
	color: #2386ff;
}


.btn-icon-add
{
	color: #13ac00;
	cursor: pointer;
}
.btn-icon-edit
{
	color: #005ce8;
	cursor: pointer;
}
.btn-icon-show
{
	color: #618ae8;
	cursor: pointer;
}
.btn-icon-delete
{
	color: #e84646;
	cursor: pointer;
}

/**********************************************************************************************************************/

.label-status
{
	background-color: #ededed;
	color: #01021D;
	display: inline-block;
	padding: 4px 8px;
	border-radius: 4px;
}
.label-status.paid
{
	background-color: #e7f7ef;
	color: #0faf62;
}
.label-status.unpaid, .label-status.waiting-answer
{
	background-color: #fff2d5;
	color: #ad8a3c;
}
.label-status.completed, .label-status.answered
{
	background-color: #f0f6ff;
	color: #005ce8;
}
.label-status.payment-failed
{
	background-color: #fffdea;
	color: #9f8d50;
}
.label-status.cancelled
{
	background-color: #ffeae8;
	color: #d9534f;
}

.label-status.link
{
	cursor: pointer;
}

.label-tag
{
	background-color: #ced7e1;
	color: #53677d;
	font-size: 13px;
	display: inline-block;
	padding: 4px 8px;
	margin-right: 4px;
	border-radius: 4px;
	white-space: nowrap;
}
.label-tag:last-child
{
	margin-right: 0;
}

/**********************************************************************************************************************/

.w-max-300
{
	width: 100%;
	max-width: 300px;
}

.w-30
{
	width: 30px;
}
.w-40
{
	width: 40px;
}
.w-60
{
	width: 60px;
}
.w-80
{
	width: 80px;
}
.w-100
{
	width: 100px;
}
.w-120
{
	width: 120px;
}
.w-140
{
	width: 140px;
}
.w-160
{
	width: 160px;
}
.w-180
{
	width: 180px;
}

/**********************************************************************************************************************/

.dialog .MuiDialog-paper
{
	padding: 24px;
}
.dialog-title
{
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0 8px 0 !important;
	margin-bottom: 24px !important;
	border-bottom: 2px solid #e5e7e8;
}
.dialog-title .title
{
	color: #01021D;
	font-weight: 700;
	font-size: 18px;
	font-family: "MetronicPro", sans-serif !important;
}
.dialog-close
{
	width: 24px;
	height: 24px;
	/*float: right;*/
	cursor: pointer;
}

.dialog-content
{
	line-height: 30px;
	color: #01021D;
	font-weight: 300;
	font-size: 16px;
	text-align: justify;
	padding: 0 !important;
}
.dialog-content .scrollbar
{
	width: 100% !important;
	height: 350px !important;
}

.dialog-content-des p
{
	/*font-size: 14px;*/
}
.dialog-content-des .title
{
	font-size: 18px;
	font-weight: 700;
}

.dialog-actions
{
	padding: 16px 0 0 0 !important;
	justify-content: flex-start !important;
}

/**********************************************************************************************************************/

.page-section-container
{
	max-width: unset !important;
	padding: 0 !important;
}

.page-section-box
{
	background-color: #ffffff;
	width: 100%;
	padding: 24px;
	border-radius: 8px;
	box-sizing: border-box;
}
/*.page-section-box .section-title*/
/*{*/
/*	!*color: #a4a4a4;*!*/
/*	color: #798593;*/
/*	!*font-weight: 500;*!*/
/*	font-weight: 500;*/
/*	font-size: 16px;*/
/*	padding-bottom: 8px;*/
/*	border-bottom: 1px solid #e8e8e8;*/
/*}*/
.page-section-box .content
{
	margin-top: 24px;
}

.page-section-box .content .btn-section
{
	text-transform: unset;
	margin-bottom: 12px;
}
.page-section-box .content .btn-section:last-child
{
	margin-bottom: 0;
}

.page-section-box .section-des
{
	text-align: justify;
}

/* * */

.section-title
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid #eeeeee;
	text-transform: uppercase;
}
.section-title .title-box
{
}
.section-title .title-box .title
{
	font-size: 22px;
	font-weight: 700;
}
.section-title .title-box .title-t2
{
	font-size: 22px;
	font-weight: 300;
}

/* btn link */

.btn-custom
{
	background-color: #CF2222;
	/*width: 100%;*/
	height: 52px;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 45px;
	border: 1px solid #CF2222;
	border-radius: 8px;
	box-sizing: border-box;
	cursor: pointer;
	transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
}
.btn-custom.full
{
	width: 100%;
}
.btn-custom:hover
{
	background-color: #FFFFFF;
	color: #CF2222;
}

.btn-custom.t2
{
	background-color: #FFFFFF;
	color: #CF2222;
}
.btn-custom.t2:hover
{
	background-color: #CF2222;
	color: #FFFFFF;
}

.btn-custom.t2.green
{
	color: #03b326;
	border: 1px solid #03b326;
}
.btn-custom.t2.green:hover
{
	background-color: #03b326;
	color: #ffffff;
}

.link-custom
{
	color: #01021D;
	font-size: 16px;
	font-weight: 400;
	transition: color 500ms ease-in-out;
}
.link-custom.border-bottom
{
	/*padding-bottom: 0px;*/
	border-bottom: 1px solid #01021D;
}
.link-custom:hover
{
	color: #CF2222;
}

/**********************************************************************************************************************/

.table-new-order-data .net-total, .table-new-order-data .package-discount, .table-new-order-data .package-price
{
	color: #0041a2;
	font-size: 14px;
	font-weight: bold;
}
.table-new-order-data .package-discount
{
	color: #0faf62;
}
.table-new-order-data .package-price
{
	color: #0041a2;
}
.table-new-order-data .net-total .td-title,
.table-new-order-data .package-discount .td-title,
.table-new-order-data .package-price .td-title
{
	text-align: right;
}
.table-new-order-data .net-total .td-title .des
{
	font-size: 12px;
	font-weight: normal;
}
.table-new-order-data input::-webkit-outer-spin-button, .table-new-order-data input::-webkit-inner-spin-button
{
	-webkit-appearance: none;
	margin: 0;
}
.table-new-order-data input[type=number]
{
	-moz-appearance: textfield;
}
.table-new-order-data .th-qty
{
	min-width: 100px;
}
.table-new-order-data .td-qty
{
	position: relative;
}
.table-new-order-data .td-qty .form-input-number
{
	margin-bottom: 0;
}
.table-new-order-data .btn-increase-decrease-box
{
	width: 40px;
	display: inline-flex;
	flex-direction: column;
	position: absolute;
	top: 12px;
	right: 12px;
}
.table-new-order-data .btn-increase-decrease-box button
{
	width: 40px;
	min-width: unset;
	height: 20px;
	border-radius: 0;
}

.table-new-order-link-data .form-input
{
	min-width: 160px;
	margin-bottom: 0;
}
.table-new-order-link-data th
{
	padding: 8px 4px 8px 0 !important;
}
.table-new-order-link-data td
{
	padding: 4px 4px 4px 0 !important;
}
.table-new-order-link-data td:first-child
{
	min-width: 40px;
	text-align: center;
}

.table-new-order-data tfoot td
{
	padding-bottom: 0;
}
.table-new-order-data tfoot tr:not(:first-child) td
{
	border: 0;
}
.table-new-order-data tfoot tr:last-child td
{
	padding-bottom: 16px;
	border-bottom: 3px solid #e5e7e8;
}

.row-btn-build-package-checkbox-csv
{
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 32px;
}
.row-btn-build-package-checkbox-csv .checkbox-box
{
	font-size: 14px;
	margin: 0;
}
.row-btn-build-package-checkbox-csv .checkbox-box .MuiCheckbox-root
{
	padding: 0;
}
.row-btn-build-package-checkbox-csv .checkbox-box .csv-des
{
	color: #01021D;
	font-size: 12px;
}
.row-btn-build-package-checkbox-csv button
{
	margin-top: 16px;
}

.new-order-input-csv-upload
{
	display: none !important;
}

/**********************************************************************************************************************/

.grecaptcha-badge
{
	display: none;
}

/**********************************************************************************************************************/

.tox-notifications-container
{
	display: none !important;
}
