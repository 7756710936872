.pagination-me-box
{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pagination-me-data
{
	color: #01021D;
	font-weight: normal;
	font-size: 14px;
	float: left;
}

.pagination-me
{
	float: right;
	padding: 0;
	margin: 0;
}
.pagination-me li
{
	float: left;
	list-style: none;
}
.pagination-me li span
{
	background-color: transparent;
	width: 36px;
	height: 36px;
	line-height: 36px;
	color: #01021D;
	font-size: 14px;
	font-weight: normal;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	border-radius: 100%;
	text-decoration: none;
	cursor: pointer;
	transition: 0.3s all ease;
}
.pagination-me li span:focus, .pagination-me li span:hover, .pagination-me li.active span
{
	background-color: rgba(207, 34, 34, 0.05);
	color: #01021D;
}

.pagination-me li.space span
{
	background-color: transparent !important;
	color: #01021D !important;
	cursor: default !important;
}

.pagination-me li.icon span
{
	/*background-color: #eefff6;*/
	/*color: #01021D;*/
}
.pagination-me li.icon span svg
{
	font-size: 18px;
}
.pagination-me li.icon span:focus, .pagination-me li.icon span:hover
{
	background-color: rgba(207, 34, 34, 0.05);
	color: #01021D;
}
