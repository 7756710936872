.page-order-show .form-label
{
	width: 200px;
}

.page-order-show .order-list .row
{
	margin: 0 -1px;
}
.page-order-show .order-list [class*='col-']
{
	padding: 0 1px;
}
.page-order-show .order-list .row-head
{
	color: #01021d;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	position: relative;
}
.page-order-show .order-list .row-head.t2
{
	background-color: #01021d;
	color: #ffffff;
	padding: 14px;
	margin-top: 28px;
	border-radius: 8px;
}
.page-order-show .order-list .row-body
{
	color: #01021d;
	font-size: 14px;
	font-weight: 700;
	margin-top: 5px;
	position: relative;
}
.page-order-show .order-list .btn-icon-delete
{
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
}
.page-order-show .order-list .row-body.t2 .btn-icon-add
{
	position: absolute;
	top: -15px;
	left: 50%;
	transform: translateX(-50%);
}






.page-order-show .col-data
{
	margin-bottom: 8px;
}
.page-order-show .data-title
{
	background-color: #ebebeb;
	color: #01021d;
	font-size: 12px;
	font-weight: 700;
	display: inline-block;
	padding: 2px 8px 2px 8px;
	margin-left: 8px;
	border: 4px solid #ffffff;
	border-radius: 8px;
}
.page-order-show .data-value
{
	background-color: #fafafa;
	color: #01021d;
	font-size: 14px;
	font-weight: 400;
	padding: 32px 16px 16px 16px;
	margin-top: -16px;
	border-radius: 8px;
}



.page-order-show .row-image-body
{
	margin-top: 24px;
}
.page-order-show .image-box
{
	background-color: #fafafa;
	width: 100%;
	color: #01021d;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 8px;
	margin: 0 0 8px 0 !important;
	border-radius: 8px;
}
.page-order-show .image-box img
{
	width: 64px !important;
	height: 64px;
	margin-right: 16px;
	object-fit: cover;
	-o-object-fit: cover;
}

.page-order-show .file-box
{
	background-color: #fafafa;
	width: fit-content;
	color: #01021d;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 8px;
	margin: 0 0 8px 0 !important;
	border-radius: 8px;
}

.page-order-show .row-price
{
	margin-top: 24px;
}
.page-order-show .price-box
{
	background-color: #01021d;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	color: #ffffff;
	font-weight: 300;
	font-size: 16px;
	padding: 20px 0;
	border-radius: 10px;
}
.page-order-show .price-box .item
{
	width: 33.33%;
	display: inline-flex;
	align-items: center;
	padding: 0 20px;
}
.page-order-show .price-box .item:nth-child(2)
{
	border: 1.5px dashed rgba(255, 255, 255, 0.7);
	border-top: 0;
	border-bottom: 0;
}
.page-order-show .price-box .item .icon
{
	width: 28px;
	height: 28px;
	margin-right: 8px;
}
.page-order-show .price-box .item .title-price
{
	flex: 1;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
}
.page-order-show .price-box .item .price
{
	font-weight: 700;
}





