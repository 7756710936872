.loading-over
{
	backdrop-filter: blur(2px);
}

/*
.loading-over
{
	background-color: rgba(255, 255, 255, 0.35);
	width: 100%;
	height: 100%;
	text-align: center;
	backdrop-filter: blur(3px);
	position: absolute;
	top: 0;
	left: 0;
}
.loading-over img
{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
*/
