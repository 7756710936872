.chat-box
{
	width: 100%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.chat-box .message-body
{
	background-color: #f5f5f5;
	width: 100%;
	flex: 1;
	padding: 32px 24px;
	border-radius: 12px;
	box-sizing: border-box;
}
.chat-box .message-body .message-row
{
	width: 100%;
	display: inline-flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 32px;
}
.chat-box .message-body .message-row:last-child
{
	margin-bottom: 0;
}
.chat-box .message-body .message-row .user-image
{
	width: 42px;
	height: 42px;
	margin-right: 14px;
	border: 2px solid #ffffff;
	border-radius: 100%;
}
.chat-box .message-body .message-row .message-box
{
	flex: 1;
	max-width: 400px;
}
.chat-box .message-body .message-row .message-box .message
{
	background-color: #ffffff;
	line-height: 24px;
	font-weight: 400;
	font-size: 12px;
	padding: 16px 14px;
	border-radius: 4px;
}

.chat-box .message-body .message-row .message-box .link-file, .chat-box .message-body .message-row .message-box .link-file:visited
{
	background-color: #ededed;
	color: #01021d;
	font-weight: 400;
	font-size: 12px;
	display: inline-flex;
	align-items: center;
	padding-right: 16px;
	border-radius: 4px;
	overflow: hidden;
}
.chat-box .message-body .message-row .message-box .link-file .name
{
	max-width: 120px;
	line-height: 24px;
	height: 24px;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.chat-box .message-body .message-row .message-box .link-file .icon
{
	background-color: #e2e2e2;
	padding: 16px;
	margin-right: 16px;
}
.chat-box .message-body .message-row .message-box .link-file:hover
{
	color: #cf2222;
}
.chat-box .message-body .message-row .message-box .link-file .size
{
	color: #01021d;
	font-weight: 400;
	font-size: 10px;
	margin-left: 10px;
}

.chat-box .message-body .message-row .message-box .date-time
{
	font-weight: 600;
	font-size: 14px;
	margin-top: 16px;
}

.chat-box .message-body .message-row.t2
{
	flex-direction: row-reverse;
}
.chat-box .message-body .message-row.t2 .user-image
{
	margin-right: 0;
	margin-left: 14px;
}
.chat-box .message-body .message-row.t2 .message-box
{
	text-align: right;
}
.chat-box .message-body .message-row.t2 .message-box .message
{
	background-color: #01021d;
	color: #ffffff;
	text-align: left;
}
.chat-box .message-body .message-row.t2 .message-box .date-time
{
	text-align: right;
}

.chat-box .message-send-bar
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin-top: 30px;
}
.chat-box .message-send-bar .form-textarea
{
	width: unset;
	flex: 1;
	margin-bottom: 0;
}
.chat-box .message-send-bar .btn
{
	background-color: #ffffff;
	width: 52px;
	height: 52px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	border: 1px solid #eeeeee;
	border-radius: 8px;
	cursor: pointer;
}
.chat-box .message-send-bar .btn .icon
{
	width: 24px;
	height: 24px;
	color: #01021d;
}
.chat-box .message-send-bar .btn.btn-submit
{
	background-color: #cf2222;
	border: 1px solid #cf2222;
}
.chat-box .message-send-bar .btn.btn-submit .icon
{
	color: #ffffff;
}
.chat-box .message-send-bar .message-file-input
{
	display: none;
}
