.page-program-add .form-label
{
	width: 200px;
}

.page-program-add .program-list .row
{
	margin: 0 -1px;
}
.page-program-add .program-list [class*='col-']
{
	padding: 0 1px;
}
.page-program-add .program-list .row-head
{
	color: #01021d;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	position: relative;
}
.page-program-add .program-list .row-head.t2
{
	background-color: #01021d;
	color: #ffffff;
	padding: 14px;
	margin-top: 28px;
	border-radius: 8px;
}
.page-program-add .program-list .row-body
{
	color: #01021d;
	font-size: 14px;
	font-weight: 700;
	margin-top: 5px;
	position: relative;
}
.page-program-add .program-list .btn-icon-delete
{
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
}
.page-program-add .program-list .btn-icon-add
{
}
.page-program-add .program-list .row-body.t2
{

}
.page-program-add .program-list .row-body.t2 .btn-icon-add
{
	position: absolute;
	top: -15px;
	left: 50%;
	transform: translateX(-50%);
}
